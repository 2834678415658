<template>
  <a v-if="id" :class="className2" href="#" @click="viewMemberDetails($event)">{{text}}</a>
  <span v-else :class="className2">{{text}}</span>
</template>
<script>
export default {
  name: 'StakingMemberDetailsLink',
  data() {
    return {};
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
  },
  computed: {
    className2() {
      return `staking-member-details-link ${this.className} ${!this.id ? 'non-link' : ''}`;
    },
  },
  methods: {
    viewMemberDetails(event) {
      if (this.id) {
        this.$router.push(`/admin/members/${this.id}/details`);
      }
    },
  },
};
</script>

<style lang="scss">
.staking-member-details-link {
  color: #5f55e7;
  font-size: 14px;
  letter-spacing: 0;
  text-decoration: underline;

  &.non-link {
    text-decoration: none;
    color: #4a4a4a;
    font-size: 14px;
    font-weight: 300;
  }
}
</style>
