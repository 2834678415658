<template>
  <div class="user-asset-table-container">
    <CCard class="user-asset-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template #createdAt="{item}">
          <td>{{ item.createdAt | moment(dateFormat) }}</td>
        </template>

        <template #email="{item}">
          <td>
            <StakingMemberDetailsLink :id="item.member_id" :text="item.member_email" />
          </td>
        </template>

        <template #platform="{item}">
          <td>
            <span>{{item.platform | mappingPlatform}}</span>
          </td>
        </template>

        <template #amount="{item}">
          <td class="text-right">
            <StakingCurrency :value="item.amount" :currencySymbol="item.platform" />
          </td>
        </template>

        <template #balance="{item}">
          <td class="text-right">
            <StakingCurrency :value="item.balance" :currencySymbol="item.platform" />
          </td>
        </template>

        <template #reward="{item}">
          <td class="text-right">
            <StakingCurrency :value="item.reward" :currencySymbol="item.rewardSymbol" />
          </td>
        </template>

        <template #address="{item}">
          <td>
            <StakingExplorerLink type="address" :currencySymbol="item.platform" :value="item.address" />
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StakingCurrency from '@/components/StakingCurrency';
import StakingBadge from '@/components/StakingBadge';
import StakingMemberDetailsLink from '@/components/StakingMemberDetailsLink';
import { Constants } from '@/constants';
import StakingExplorerLink from '@/components/StakingExplorerLink';

export default {
  name: 'UserAssetListTable',
  components: {
    StakingCurrency,
    StakingMemberDetailsLink,
    StakingExplorerLink,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'createdAt',
          label: this.$t('USER_ASSET_LIST_TABLE_COMPONENT_DATE_COL'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'platform',
          label: this.$t('USER_ASSET_LIST_TABLE_COMPONENT_PLATFORM_COL'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'address',
          label: this.$t('USER_ASSET_LIST_TABLE_COMPONENT_ADDRESS_COL'),
          _style: ('min-width: 480px'),
        },
        {
          key: 'balance',
          label: this.$t('USER_ASSET_LIST_TABLE_COMPONENT_BALANCE_COL'),
          _style: 'min-width: 80px; text-align: right;',
        },
        {
          key: 'amount',
          label: this.$t('USER_ASSET_LIST_TABLE_COMPONENT_STAKED_AMOUNT_COL'),
          _style: 'min-width: 80px; text-align: right;',
        },
        {
          key: 'reward',
          label: this.$t('USER_ASSET_LIST_TABLE_COMPONENT_REWARD_COL'),
          _style: 'min-width: 80px; text-align: right;',
        },
        {
          key: 'reward',
          label: this.$t('USER_ASSET_LIST_TABLE_COMPONENT_REWARD_COL'),
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`commission-payout/${item.id}/details`);
    },
  },
};
</script>

<style lang="scss">
.user-asset-table-container {
  .user-asset-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>
