var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-asset-table-container"},[_c('CCard',{staticClass:"user-asset-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,_vm.dateFormat)))])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingMemberDetailsLink',{attrs:{"id":item.member_id,"text":item.member_email}})],1)]}},{key:"platform",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(_vm._f("mappingPlatform")(item.platform)))])])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_c('StakingCurrency',{attrs:{"value":item.amount,"currencySymbol":item.platform}})],1)]}},{key:"balance",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_c('StakingCurrency',{attrs:{"value":item.balance,"currencySymbol":item.platform}})],1)]}},{key:"reward",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[_c('StakingCurrency',{attrs:{"value":item.reward,"currencySymbol":item.rewardSymbol}})],1)]}},{key:"address",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingExplorerLink',{attrs:{"type":"address","currencySymbol":item.platform,"value":item.address}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }